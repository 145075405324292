<template>
  <div>
    <p>이것은 어드민 페이지입니다.</p>
    <router-view class="content"></router-view>
  </div>
</template>

<script>
export default {
  name: 'Admin',

};
</script>

<style></style>
